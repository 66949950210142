<template>
  <v-container fluid>
    <v-row class='justify-center pb-16 mb-16 d-none d-sm-flex'>
      <v-col cols=12 md="auto">
<!--        <div id='div-gpt-ad-1651756535564-0'>-->

<!--        </div>-->
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
export default {
  name: 'HomeContent',
  computed: {
    textSize() {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs':
        case 'sm':
          return "home-central-text-mobile"

        default : return  "home-central-text"
          // case 'md': return 170
          // case 'lg': return 170
          // case 'xl': return 170
      }
    },
  },
  // mounted() {
  //   // if(this.$vuetify.breakpoint.name != 'xs' && this.$vuetify.breakpoint.name != 'sm') {
  //   //   window.googletag = window.googletag || {cmd: []};
  //   //   window.googletag.cmd.push(function() {
  //   //     window.googletag.defineSlot('/21806670553/LEITA_LandingCenter_728x90', [728, 90], 'div-gpt-ad-1651756535564-0').addService(window.googletag.pubads());
  //   //     window.googletag.pubads().enableSingleRequest();
  //   //     window.googletag.enableServices();
  //   //   });
  //   //   window.googletag.cmd.push(function() { window.googletag.display('div-gpt-ad-1651756535564-0'); });
  //   // }
  // },
}


</script>
